import { computed } from '@ember/object';
import { alias, and, equal, filter, gt, lt, notEmpty, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isBlank, isEmpty, isEqual, isNone, isPresent } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import moment from 'moment';
import twitter from 'twitter-text';

import IgPreviewPost from 'later/models/ig-preview-post';
import { areStringsEqual } from 'later/utils/compare-strings';
import {
  INSTAGRAM_MAX_COMMENT_MENTION_COUNT,
  VIDEO_STATUS,
  PostType,
  INSTAGRAM_POST_TYPE,
  PlatformPostType,
  HttpMethod
} from 'later/utils/constants';
import { isValidUrl } from 'later/utils/is-valid-url';
import { convert, timestamp, MINUTES_PER_HOUR } from 'later/utils/time-format';
import Validations from 'later/validations/gram';
import getHashtags from 'shared/utils/get-hashtags';
import getMentions from 'shared/utils/get-mentions';

import type LinkinbioPostModel from './linkinbio-post';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type { ValidationError } from 'ember-cp-validations';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type GeneratedCaption from 'later/models/generated-caption';
import type { IgPreviewPostParams } from 'later/models/ig-preview-post';
import type { CopyableModel } from 'later/models/interfaces/copyable-model';
import type MediaItemModel from 'later/models/media-item';
import type PostActivityModel from 'later/models/post-activity';
import type PostMediaItemModel from 'later/models/post-media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type PerformanceTrackingService from 'later/services/performance-tracking';
import type PostService from 'later/services/schedule/post';
import type UserConfigService from 'later/services/user-config';
import type UserRoleService from 'later/services/user-role';
import type { Moment } from 'moment';
import type { Maybe, ValueOfType } from 'shared/types';
import type { YoutubePrivacyStatus } from 'shared/types/youtube';

export enum GramState {
  Draft = 'draft',
  Failed = 'failed',
  Posted = 'posted',
  Publishing = 'publishing',
  Ready = 'ready',
  Retrying = 'retrying',
  Scheduled = 'scheduled',
  Verified = 'verified'
}

type GramType = 'text' | 'video' | 'image';

type GramVerifyParams = {
  external_post_id: string;
  external_post_created_time: string;
};

type ScheduleEventArgs = Record<PropertyKey, unknown>;

export const MetricsAchieved = {
  CommentCount: 'comment_count',
  LikeCount: 'like_count',
  Reach: 'reach'
} as const;

export type MetricsAchievedType = ValueOfType<typeof MetricsAchieved>;

export type ApprovedBy = {
  user_id: string;
  user_approved_time: number;
};

/**
 * Represents a single post.
 */
export default class GramModel extends Model.extend(Validations) implements CopyableModel<GramModel> {
  @service declare intl: IntlService;
  @service declare performanceTracking: PerformanceTrackingService;
  @service declare userConfig: UserConfigService;
  @service declare userRole: UserRoleService;
  @service('schedule/post') declare schedulePost: PostService;

  /**
   * Whether the post is active
   */
  @attr('boolean', { defaultValue: true }) declare active: boolean;

  /**
   * Which album the Pinterest post belongs to
   */
  @attr('string') album: Maybe<string>;

  /**
   * Whether the TikTok post allows comment
   */
  @attr('boolean', { defaultValue: true }) declare allowTiktokComment: boolean;

  /**
   * Whether the TikTok post allows duet
   */
  @attr('boolean', { defaultValue: false }) declare allowTiktokDuet: boolean;

  /**
   * Whether the TikTok post allows Stitch
   */
  @attr('boolean', { defaultValue: false }) declare allowTiktokStitch: boolean;

  /**
   * Whether TikTok automatically adds recommended music to the post.
   */
  @attr('boolean', { defaultValue: false }) declare autoAddMusic: boolean;

  /**
   * Whether the post is an auto publish post
   */
  @attr('boolean', { defaultValue: false }) declare autoPublish: boolean;

  /**
   * Whether the post is made using a bttp time slot
   */
  @attr('boolean', { defaultValue: false }) declare scheduledForBttp: boolean;

  /**
   * The post's caption
   */
  @attr('string', { defaultValue: '' }) declare caption: string;

  /**
   * The category of a Youtube post
   */
  @attr('string') category?: string;

  /**
   * Whether click tracking is enabled for this post
   */
  @attr('boolean', { defaultValue: false }) declare clickTracking: boolean;

  /**
   * An array of strings representing the collaborators of the Instagram post
   */
  @attr({ defaultValue: () => [] }) declare collaborators: string[];

  /**
   * The time the post was created in unix time
   */
  @attr('number') declare readonly createdTime: number;

  /**
   * The post's crop pixel positions used to crop the image
   * e.g. ["186", "332", "718", "820"]
   */
  @attr() cropArray: Maybe<string[]>;

  /**
   * Whether the post has been delivered
   */
  @attr('boolean', { defaultValue: false }) declare delivered: boolean;

  /**
   * Timestamp of when the external post share is created
   */
  @attr('number') declare externalReviewRequestedTime?: number;

  /**
   * Timestamp of when the last post share external comment is received
   */
  @attr('number') declare externalReviewReceivedTime?: number;

  /**
   * First comment for the post
   */
  @attr('string', { defaultValue: '' }) declare firstComment: string;

  /**
   * Error while publishing the first comment
   */
  @attr('string') firstCommentError: Maybe<string>;

  /**
   * Facebook Graph ID used to identify the post
   */
  @attr('string') readonly facebookMediaId: Maybe<string>;

  /**
   * The post media type
   */
  @attr('string') readonly gramType: Maybe<GramType>;

  /**
   * The high resolution URL of the image for the post
   */
  @attr('string') readonly highResUrl: Maybe<string>;

  /**
   * Whether this Instagram Reel or Linkedin Video post has a custom thumbnail
   */
  @attr('boolean') hasCustomThumbnail: Maybe<boolean>;

  /**
   * The URL of the image for the post
   */
  @attr('string') readonly imageUrl: Maybe<string>;

  /**
   * Whether the post is an Instagram story. This is only set on the client side.
   * The {@link GramModel.isInstagramStory} property should be used instead, as it
   * also reads the value from the server.
   */
  @attr('boolean') isStory: Maybe<boolean>;

  /**
   * Whether to enable the Brand Organic Content toggle for the TikTok video.
   */
  @attr('boolean', { defaultValue: false }) declare isBrandOrganic: boolean;

  /**
   * Whether to enable the Branded Content toggle for the TikTok video.
   */
  @attr('boolean', { defaultValue: false }) declare isBrandedContent: boolean;

  /**
   * Whether the post is a draft post
   */
  @attr('boolean', { defaultValue: false }) declare isDraft: boolean;

  /**
   * The LinkinBio URL for the post
   */
  @attr('string') linkUrl: Maybe<string>;

  /**
   * Location ID associated with the post's location
   */
  @attr('string') locationId: Maybe<string>;

  /**
   * Location name associated with post's location
   */
  @attr('string') locationName: Maybe<string>;

  /**
   * Whether the post is in a locked state
   */
  @attr('boolean', { defaultValue: false }) declare readonly locked: boolean;

  /**
   * The low resolution URL of the image for the post
   */
  @attr('string') readonly lowResUrl: Maybe<string>;

  /**
   * The target audience for a Youtube post
   */
  @attr('boolean') madeForKids?: boolean;

  /**
   * The post's main image URL
   */
  @attr('string') readonly mainImageUrl: Maybe<string>;

  /**
   * The medium thumbnail URL for the post
   */
  @attr('string') readonly medThumbnailUrl: Maybe<string>;

  /**
   * The media ID of the post
   * Use the verify endpoint if mediaId needs to be set
   */
  @attr('string') readonly mediaId: Maybe<string>;

  /**
   * An array that contains either "reach", "like_count" and/or "comment_count"
   * Used to determine if this is a high-performing post
   */
  @attr() readonly metricsAchieved?: Array<MetricsAchievedType>;

  /**
   * Whether a Youtube post should notify subscribers
   */
  @attr('boolean', { defaultValue: false }) declare notifySubscribers: boolean;

  /**
   * Whether a Snapchat post should save to profile
   */
  @attr('boolean', { defaultValue: true }) declare saveToProfile: boolean;

  /**
   * Locale for Snapchat posts
   */
  @attr('string', { defaultValue: 'en_US' }) declare snapLocale: boolean;

  /**
   * The original caption of the post
   */
  @attr('string', { defaultValue: '' }) declare originalCaption: string;

  /**
   * The original link URL of the post
   */
  @attr('string') originalLinkUrl: Maybe<string>;

  /**
   * The post's parent ID
   */
  @attr('number') parentId: Maybe<number>;

  /**
   * The platform error message of the post
   */
  @attr('string') readonly platformError: Maybe<string>;

  /**
   * The array of platform errors for the post
   */
  @attr() readonly platformErrors: Maybe<{ code: number }[]>;

  /**
   * The platform URL of the post
   */
  @attr('string') readonly platformUrl: Maybe<string>;

  /**
   * The platform warning for the post
   */
  @attr('string') platformWarning: Maybe<string>;

  /**
   * Defines a post's type for video posts to Facebook/Instagram/Youtube
   * ie. If the post is a Reel, Feed Post, Page Post, or Short
   */
  @attr('string') postType: Maybe<string>;

  /**
   * The unix time the post was posted
   */
  @attr('number') postedTime: Maybe<number>;

  /**
   * The scheduled time for the post
   */
  @attr('number') scheduledTime: Maybe<number>;

  /**
   * Share To Feed (For Instagram's Reel)
   */
  @attr('boolean', { defaultValue: true }) declare shareToFeed: boolean;

  /**
   * The small thumbnail URL for the post
   */
  @attr('string') readonly smallThumbnailUrl: Maybe<string>;

  /**
   * The username from the post's original source
   */
  @attr('string') readonly sourceUsername: Maybe<string>;

  /**
   * The current state the post is in
   * e.g posted, verified
   */
  @attr('string') readonly state: Maybe<GramState>;

  /**
   * If the post has been submitted for approval
   * Only valid for new grams, once gram is
   * saved in the Back-End, use this.isApprovalSubmitted
   */
  @attr('boolean') declare approvalSubmitted?: boolean;

  /**
   * The time the post was submitted for approval
   */
  @attr('number') declare readonly approvalSubmittedTime?: number;

  /**
   * An array of Approvers and approved time
   */
  @attr({ defaultValue: () => [] }) declare approvedBy: ApprovedBy[];

  @attr('boolean') declare pendingApproval?: boolean;

  /**
   * A list of tags/keywords describing the post
   * Currently only used for Youtube
   */
  @attr({ defaultValue: () => [] }) declare keywordTags: string[];

  /**
   * The thumbnail sized image URL
   */
  @attr('string') readonly thumbUrl: Maybe<string>;

  /**
   * The title of the post
   */
  @attr('string', { defaultValue: '' }) declare title: string;

  /**
   * The type of the post
   */
  @attr('string') type?: PlatformPostType;

  /**
   * A list of users tagged in the post
   */
  @attr({ defaultValue: () => [] }) declare userTags: { username: string }[];

  /**
   * Time the post was verified
   */
  @attr('number') readonly verifiedTime: Maybe<number>;

  /**
   * The video status of a TikTok post
   */
  @attr('string', { defaultValue: '' }) declare readonly videoStatus: string;

  /**
   * The video URL of the post if the post is a video
   */
  @attr('string') readonly videoUrl: Maybe<string>;

  /**
   * The privacy settings of a Youtube post
   */
  @attr('string') visibility?: YoutubePrivacyStatus;

  /**
   * Which LinkinBio post this post is associated with
   */
  @belongsTo('linkinbioPost', { async: true }) declare linkinbioPost: AsyncBelongsTo<LinkinbioPostModel | null>;

  /**
   * The media item that the post is associated with
   */
  @belongsTo('mediaItem', { async: true }) declare mediaItem: AsyncBelongsTo<MediaItemModel | null>;

  /**
   * The social profile the post belongs to
   */
  @belongsTo('socialProfile', { async: true }) declare socialProfile: AsyncBelongsTo<SocialProfileModel | null>;

  /**
   * The user the post belongs to
   */
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel | null>;

  /**
   * Associated 'GeneratedCaption' items
   */
  @hasMany('generatedCaption', { async: true }) declare generatedCaptions: AsyncHasMany<GeneratedCaption>;

  /**
   * Associated 'PostActivity' items
   */
  @hasMany('postActivity', { async: true }) declare postActivities: AsyncHasMany<PostActivityModel>;

  /**
   * Associated Post media items
   */
  @hasMany('postMediaItem', { async: false }) declare postMediaItems: PostMediaItemModel[];

  @alias('socialProfile.account') declare account: AccountModel;
  @alias('firstPostMediaItem.mediaItem') declare firstMediaItem: MediaItemModel;
  @alias('isPosted') declare posted: boolean;
  @alias('validations.isValid') declare validationsValid: boolean;

  @and('autoPublish', 'isFirstCommentActive') declare firstCommentEnabled: boolean;

  @equal('gramType', 'image') declare isImage: boolean;
  @equal('gramType', 'text') declare isText: boolean;
  @equal('gramType', 'video') declare isVideo: boolean;
  @equal('captionHashtags', 0) declare isCaptionHashtagsEmpty: boolean;
  @equal('firstCommentHashtags', 0) declare isFirstCommentHashtagsEmpty: boolean;
  @equal('type', 'InstagramStory') declare isInstagramStoryType: boolean;
  @equal('state', 'retrying') declare isRetrying: boolean;

  /**
   * Whether the post has a video validation error
   */
  @filter('validations.errors', (error: ValidationError) => ['cropArray', 'videoUrl'].includes(error.attribute))
  declare videoValidationErrors: string[];

  @gt('postMediaItems.length', 0) declare hasPostMediaItems: boolean;
  @gt('captionHashtags', 30) declare isCaptionExceedingHashtagLimit: boolean;
  @gt('firstCommentHashtags', 30) declare isFirstCommentExceedingHashtagLimit: boolean;

  @lt('charactersLeft', 0) declare exceededCharacterLimit: boolean;
  @lt('hashtagsLeft', 0) declare exceededHashtagLimit: boolean;

  @notEmpty('errorCode') declare hasErrorCode: boolean;
  @notEmpty('firstComment') declare isFirstCommentActive: boolean;

  /**
   * Whether the post is an Instagram story
   */
  @or('isInstagramStoryType', 'isStory') declare isInstagramStory: boolean;
  @or('title.length', 'defaultTitleLength') declare titleCharacterCount: number;

  @readOnly('postMediaItems.firstObject') declare firstPostMediaItem: PostMediaItemModel;
  @readOnly('socialProfile.isFacebook') declare isFacebook: boolean;
  @readOnly('socialProfile.isInstagram') declare isInstagram: boolean;
  @readOnly('socialProfile.isLinkedin') declare isLinkedin: boolean;
  @readOnly('socialProfile.isPinterest') declare isPinterest: boolean;
  @readOnly('socialProfile.isThreads') declare isThreads: boolean;
  @readOnly('socialProfile.isTiktok') declare isTiktok: boolean;
  @readOnly('socialProfile.isTwitter') declare isTwitter: boolean;
  @readOnly('socialProfile.isYoutube') declare isYoutube: boolean;
  @readOnly('socialProfile.isSnapchat') declare isSnapchat: boolean;

  /**
   * Whether the post was created using drag and drop from side library
   */
  calendarDrop: Maybe<boolean> = null;

  /**
   * Default title length for a Pinterest post
   */
  defaultTitleLength = 0;

  /**
   * Whether the post is from Later
   */
  isLaterPost = true;

  /**
   * The preview data URL used for the post's preview
   */
  previewDataUrl: Maybe<string> = null;

  /**
   * Max limit of characters in a Pinterest or Youtube title
   */
  titleMaxCharacters = 100;

  /**
   * Max limit of characters for Youtube Tags
   */
  youtubeTagsMaxCharacters = 500;

  approve = memberAction<never, { gram: GramModel }>({
    path: 'approve',
    type: HttpMethod.Patch,
    after(this: GramModel, response: { gram: GramModel }) {
      this.reload();
      return response;
    }
  });

  /**
   * Check the post's images
   */
  checkImages = memberAction<never, void>({ path: 'check_images', type: 'post' });

  /**
   * Verify the post's properties
   */
  verify = memberAction<GramVerifyParams, { gram: GramModel }>({
    path: 'verify',
    type: 'post'
  });

  get isApprovalSubmitted(): boolean {
    return Boolean(this.approvalSubmittedTime) || Boolean(this.approvalSubmitted);
  }

  get isApproved(): boolean {
    return Boolean(this.approvedBy?.length);
  }

  @computed('type', 'postType', 'isSnapchat')
  get isSpotlight(): boolean {
    return (
      this.type === PlatformPostType.SnapchatSpotlight || (this.isSnapchat && this.postType === PostType.Spotlight)
    );
  }

  @computed('type', 'postType', 'isSnapchat')
  get isSnapchatStory(): boolean {
    return (
      this.type === PlatformPostType.SnapchatStory || (this.isSnapchat && this.postType === PostType.SnapchatStory)
    );
  }

  @computed('type', 'postType', 'isInstagram')
  get isInstagramReel(): boolean {
    return this.type === PlatformPostType.InstagramReel || (this.isInstagram && this.postType === PostType.Reel);
  }

  @computed('type', 'postType', 'isFacebook')
  get isFacebookReel(): boolean {
    return this.type === PlatformPostType.FacebookReel || (this.isFacebook && this.postType === PostType.Reel);
  }

  @computed('isLinkedin', 'isVideo')
  get isLinkedinVideo(): boolean {
    return (
      this.isLinkedin &&
      this.isVideo &&
      ((this.postMediaItems.length === 2 && this.hasCustomThumbnail) ||
        (this.postMediaItems.length === 1 && !this.hasCustomThumbnail))
    );
  }

  @computed('isFacebookReel', 'isInstagramReel')
  get isReel(): boolean {
    return this.isFacebookReel || this.isInstagramReel;
  }

  @computed('modalErrorCode')
  get hasNoTokenErrorCode(): boolean {
    return this.modalErrorCode === 'no_token';
  }

  /**
   * Whether the post belongs to any of the account's Instagram profiles
   */
  @computed('sourceUsername', 'account.instagramProfiles')
  get isOwnPost(): boolean {
    const account = this.get('account');

    if (!account?.get('instagramProfiles')?.length) {
      return false;
    }

    const isOwnPost = account
      .get('instagramProfiles')
      .any((profile: SocialProfileModel) =>
        isEqual(this.get('firstMediaItem')?.get('sourceUsername'), profile.get('nickname'))
      );

    return isOwnPost;
  }

  /**
   * Whether the post has been posted
   */
  @computed('postedTime')
  get isPosted(): boolean {
    return isPresent(this.postedTime);
  }

  get isSingleVideoFeedPost(): boolean {
    return !this.isCarouselPost && this.isInstagram && !this.isInstagramReel && this.isVideo;
  }

  /**
   * Whether a TikTok post video status is hidden from the public
   */
  @computed('videoStatus')
  get isVideoHidden(): boolean {
    return VIDEO_STATUS.NO_LONGER_PUBLICLY_AVAILABLE === this.videoStatus;
  }

  /**
   * Whether a TikTok post video status is publicly viewable
   */
  @computed('videoStatus')
  get isVideoPubliclyAvailable(): boolean {
    return VIDEO_STATUS.PUBLICLY_AVAILABLE === this.videoStatus;
  }

  /**
   * Whether a TikTok post video status is in failed state
   */
  @computed('videoStatus')
  get isVideoPublishFailed(): boolean {
    return VIDEO_STATUS.PUBLISH_FAILED === this.videoStatus;
  }

  /**
   * Whether the Tiktok post's video status is in a rejected state
   */
  @computed('videoStatus')
  get isVideoRejected(): boolean {
    const { BAD_REQUEST } = VIDEO_STATUS;
    return [BAD_REQUEST].includes(this.videoStatus);
  }

  /**
   * Small thumbnail URL if available, processing URL if not
   */
  @computed(
    'id',
    'smallThumbnailUrl',
    'processingImageUrl',
    'isInstagramReel',
    'isLinkedinVideo',
    'postMediaItems.@each.smallThumbnailUrl'
  )
  get calendarOrProcessingUrl(): string {
    if ((this.isInstagramReel || this.isLinkedinVideo) && this.customCoverPMI) {
      return this.customCoverPMI.smallThumbnailUrl || this.processingImageUrl;
    }
    if (!isNone(this.smallThumbnailUrl)) {
      return this.smallThumbnailUrl;
    }
    return this.processingImageUrl;
  }

  /**
   * Number of hashtags in the post's caption
   */
  @computed('caption')
  get captionHashtags(): number {
    return isEmpty(this.caption) ? 0 : getHashtags(this.caption).length;
  }

  /**
   * Whether the caption has a valid URL
   */
  @computed('caption')
  get captionHasURL(): boolean {
    const words = this.caption.split(/\s+/);
    for (const word of words) {
      if (isValidUrl(word)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Number of characters in the post's caption.
   *
   * For Twitter if there is a link of any length it will be altered to 23
   * characters and added onto the existing length.
   * https://dev.twitter.com/rest/reference/get/help/configuration
   *
   * For Twitter some Unicode glyphs count as more than one character and are
   * weighted as such using the twitter-text parsing library.
   * https://developer.twitter.com/en/docs/developer-utilities/twitter-text.html
   */
  @computed('isTwitter', 'caption', 'linkUrl')
  get characterCount(): number {
    if (this.isTwitter) {
      const link_reserved_count = !isEmpty(this.linkUrl) ? 23 : 0;
      return twitter.parseTweet(this.caption).weightedLength + link_reserved_count;
    }
    return this.get('caption').length || 0;
  }

  /**
   * Number of characters left in a Pinterest or Youtube post's title
   */
  @computed('titleCharacterCount', 'titleMaxCharacters')
  get titleCharactersLeft(): number {
    return this.titleMaxCharacters - this.titleCharacterCount;
  }

  /**
   * Number of characters used by the post's Youtube tags
   *
   * Youtube adds commas to separate each tag, these count towards the character limit
   * Spaces take up 2 extra characters for the quotation marks added by the API server
   *
   * @see snippet.tags https://developers.google.com/youtube/v3/docs/videos#properties
   */
  @computed('keywordTags.[]')
  get videoTagCharacterCount(): number {
    if (!this.keywordTags || this.keywordTags?.length == 0) {
      return 0;
    }

    let count = 0;
    this.keywordTags.forEach((tag) => {
      const numWhiteSpace = tag.split(' ').length - 1;
      count += tag.length + numWhiteSpace * 2;
    });

    const commaCount = this.keywordTags.length - 1;
    count += commaCount;

    return count;
  }

  /**
   * Number of characters left in a Youtube post's tags
   */
  @computed('videoTagCharacterCount', 'youtubeTagsMaxCharacters')
  get videoTagCharactersLeft(): number {
    return this.youtubeTagsMaxCharacters - this.videoTagCharacterCount;
  }

  /**
   * Number of characters left in a post's caption depending on the
   * platform
   */
  @computed('characterCount', 'maxCharacters')
  get charactersLeft(): number {
    return this.maxCharacters - this.characterCount;
  }

  /**
   * Unix timestamp of a post's posted time if posted or scheduled
   * time if scheduled
   */
  @computed('scheduledTime', 'postedTime')
  get displayTime(): Maybe<number> {
    if (!isNone(this.postedTime)) {
      return this.postedTime;
    }
    return this.scheduledTime;
  }

  /**
   * Whether the social platform of a post needs a refresh before posting
   */
  @computed('scheduledTime', 'socialProfile.{needsRefresh,tokenExpiresTime}')
  get needsRefreshBeforePosting(): boolean {
    return !this.isNotificationPost && (this.platformWillExpire || this.hasNoTokenErrorCode);
  }

  /**
   * Whether a posts social platform token will expire before the scheduled posting time
   */
  @computed('scheduledTime', 'socialProfile.{needsRefresh,tokenExpiresTime}')
  get platformWillExpire(): boolean {
    const socialProfile = this.get('socialProfile');
    const scheduledTime = Number(this.scheduledTime);

    if (!socialProfile || !scheduledTime || this.isNotificationPost) {
      return false;
    }

    const checkExpiration = socialProfile.get('willExpireBeforeTimestamp')?.bind(socialProfile);
    const platformWillExpire = checkExpiration ? checkExpiration(scheduledTime) : false;

    return platformWillExpire;
  }

  /**
   * Returns true when a draft post exists for a user who no longer has access to draft posts as part of their plan
   */
  @computed('isDraft')
  get isDisabledDraft(): boolean {
    return false;
  }

  /**
   * Returns true for draft posts with no caption and no media items
   */
  @computed('isDraft', 'firstPostMediaItem', 'caption')
  get isEmptyDraft(): boolean {
    return this.isDraft && !this.firstPostMediaItem && !this.caption;
  }

  /**
   * Whether publishing to the post's platform failed due to the post not having a publishing method
   */
  @computed('platformError.[]')
  get isNoPublishingMethodPlatformError(): boolean {
    return this.platformError?.includes('This post has no publishing method') ?? false;
  }

  /**
   * Whether the post's platform needs to refresh credentials
   */
  @computed('platformError.[]')
  get isRefreshPlatformError(): boolean {
    return this.platformError?.includes('Please refresh your connection') ?? false;
  }

  /**
   * Whether the post exceeded Instagram's max of 25 auto publish posts
   */
  @computed('platformError.[]')
  get isInstagramDailyQuotaExceededError(): boolean {
    return this.platformError?.includes('Instagram only allows 25 Auto Publish posts') ?? false;
  }

  /**
   * Whether the post exceeded Youtube's max auto publish shorts
   */
  get isYoutubeDailyQuotaExceededError(): boolean {
    return (
      this.platformError?.includes('The request cannot be completed because you have exceeded your quota.') ?? false
    );
  }

  /**
   * Whether the post is missing Linkedin admin permissions to publish
   */
  get isMissingLinkedinPermissions(): boolean {
    return this.platformError?.includes(`You don't have enough permissions for this LinkedIn organization`) ?? false;
  }

  /**
   * Formatted platform error
   */
  @computed('isRefreshPlatformError')
  get formattedPlatformError(): Maybe<string> {
    if (this.isRefreshPlatformError) {
      return this.intl.t('post.instagram_refresh_message');
    } else if (this.isMissingLinkedinPermissions) {
      return this.intl.t('post.linkedin_missing_permissions');
    }
    return this.platformError;
  }

  /**
   * Number of hashtags in an Instagram post's first comment
   */
  @computed('firstComment')
  get firstCommentHashtags(): number {
    return isEmpty(this.firstComment) ? 0 : getHashtags(this.firstComment).length;
  }

  /**
   * Number of mentions in an Instagram post's first comment
   */
  @computed('firstComment')
  get firstCommentMentionCount(): number {
    return isEmpty(this.firstComment) ? 0 : getMentions(this.firstComment).length;
  }

  /**
   * Number of hashtags in an Instagram post's caption and first comment
   */
  @computed('captionHashtags', 'firstCommentHashtags', 'firstCommentEnabled')
  get hashtagsCount(): number {
    return this.firstCommentEnabled ? this.captionHashtags + this.firstCommentHashtags : this.captionHashtags;
  }

  /**
   * Number of hashtags left in a post
   */
  @computed('hashtagsCount', 'isInstagram')
  get hashtagsLeft(): number {
    const maxHashtags = this.isInstagram ? 30 : Infinity;
    return maxHashtags - this.hashtagsCount;
  }

  /**
   * Whether to show platform error dependent on platform
   */
  @computed('modalErrorCode')
  get showPlatformError(): boolean {
    const platformErrorCodes = [
      'twitter_error',
      'facebook_error',
      'instagram_error',
      'pinterest_error',
      'linkedin_error',
      'tiktok_error',
      'threads_error',
      'snapchat_error'
    ];
    return this.modalErrorCode ? platformErrorCodes.includes(this.modalErrorCode) : false;
  }

  /**
   * Whether the post has an error
   */
  @computed('platformErrors.[]', 'validations.isValid', 'this.modalErrorCode', 'firstCommentError')
  get hasError(): boolean {
    return (
      Boolean(this.firstCommentError) ||
      !isEmpty(this.platformErrors) ||
      !isEmpty(this.modalErrorCode) ||
      !this.validationsValid
    );
  }

  /**
   * Whether to the post has an error icon in calendar view
   */
  @computed('platformErrors.[]', 'validationsValid', 'modalErrorCode')
  get hasErrorIcon(): boolean {
    const hasPlatformError = this.platformErrors
      ? this.platformErrors.any((error) => error.code === 3 || error.code === 4)
      : false;

    return hasPlatformError || !this.validationsValid || !isEmpty(this.modalErrorCode);
  }

  /**
   * High resolution URL or processing URL if none
   */
  @computed('highResUrl', 'processingImageUrl')
  get highResOrProcessingUrl(): string {
    if (!isNone(this.highResUrl)) {
      return this.highResUrl;
    }
    return this.processingImageUrl;
  }

  /**
   * A comma separated string of image text from the postMediaItems
   * or 'null_value' if none
   */
  @computed('postMediaItems.@each.textboxes')
  get imageText(): string {
    const imageTextArray: string[] = [];

    this.postMediaItems.forEach(({ textboxes }) => {
      if (isPresent(Object.values(textboxes))) {
        Object.values(textboxes).forEach(({ text }) => {
          if (text) imageTextArray.push(text);
        });
      }
    });

    return isEmpty(imageTextArray) ? 'null_value' : imageTextArray.join();
  }

  /**
   * Whether the post is auto publishing
   */
  @computed('state', 'socialProfile.{isInstagram,isTiktok}', 'autoPublish')
  get isAutoPublishing(): boolean {
    const autoPublishedPost =
      this.get('socialProfile')?.get('isInstagram') || this.get('socialProfile')?.get('isTiktok')
        ? this.autoPublish
        : true;
    return this.state === GramState.Publishing && autoPublishedPost;
  }

  /**
   * Whether a post is editable
   */
  @computed('locked', 'isAutoPublishing')
  get isEditable(): boolean {
    return !this.locked && !this.isAutoPublishing && !this.isPastPostLimitDisabled;
  }

  /**
   * Whether a post is view only
   */
  get isViewOnly(): boolean {
    return !this.isDraft && !this.isNew && this.requiresApproval;
  }

  /**
   * Whether a post requires approval
   */
  get requiresApproval(): boolean {
    return this.userRole.currentUser.needsApproval && !this.isApproved;
  }

  /**
   * Whether the post is less than 10 minutes old
   *
   * @property isRecent
   * @type {Boolean}
   */
  @computed('createdTime')
  get isRecent(): boolean {
    const diff = moment().unix() - this.createdTime;
    return diff < convert.minutes(10).toSeconds();
  }

  /**
   * Whether the video post is trimmed
   */
  @computed('isVideo', 'firstPostMediaItem.{startTime,endTime}')
  get isTrimmedVideo(): boolean {
    return (
      this.isVideo &&
      Boolean(this.get('firstPostMediaItem')?.get('startTime') || this.get('firstPostMediaItem')?.get('endTime'))
    );
  }

  /**
   * Whether the video post is cropped
   */
  @computed('isVideo', 'firstPostMediaItem.cropArray.[]')
  get isCroppedVideo(): boolean {
    return this.isVideo && Boolean(this.get('firstPostMediaItem')?.get('cropArray')?.length);
  }

  /**
   * Low resolution URL or processing URL if none
   */
  @computed('lowResUrl', 'processingImageUrl')
  get lowResOrProcessingUrl(): string {
    if (!isNone(this.lowResUrl)) {
      return this.lowResUrl;
    }
    return this.processingImageUrl;
  }

  /**
   * Max number of characters allowed for the platform's post caption
   */
  @computed('isInstagram', 'isTwitter', 'isPinterest', 'isFacebook', 'isLinkedin')
  get maxCharacters(): number {
    if (this.isInstagram) {
      return 2200;
    } else if (this.isTwitter) {
      return 280;
    } else if (this.isPinterest) {
      return 500;
    } else if (this.isFacebook) {
      return 63206;
    } else if (this.isTiktok) {
      return 2200;
    } else if (this.isLinkedin) {
      return 3000;
    } else if (this.isYoutube) {
      return 5000;
    } else if (this.isThreads) {
      return 500;
    } else if (this.isSnapchat) {
      return 160;
    }

    return 1000;
  }

  /**
   * Medium thumbnail URL or processing URL if none
   */
  @computed('medThumbnailUrl', 'smallThumbnailOrProcessingUrl', 'isInstagramReel', 'customCoverPMI')
  get medThumbnailOrProcessingUrl(): string {
    if (this.isInstagramReel && this.customCoverPMI?.medThumbnailUrl) {
      return this.customCoverPMI.medThumbnailUrl;
    }
    if (!isNone(this.medThumbnailUrl)) {
      return this.medThumbnailUrl;
    }
    return this.smallThumbnailOrProcessingUrl;
  }

  /**
   * Number of mentions left for a post
   */
  @computed('firstCommentMentionCount', 'isInstagram')
  get mentionsLeft(): number {
    const maxMentions = this.isInstagram ? INSTAGRAM_MAX_COMMENT_MENTION_COUNT : Infinity;
    return maxMentions - this.firstCommentMentionCount;
  }

  /**
   * Modal error code for the post
   *
   * @property modalErrorCode
   * @type {String}
   */
  @computed(
    'isInstagram',
    'isTwitter',
    'isPinterest',
    'isFacebook',
    'isLinkedin',
    'isTiktok',
    'isThreads',
    'isSnapchat',
    'socialProfile.token',
    'account.stopTheWorldOrPastDue',
    'platformError'
  )
  get modalErrorCode(): Maybe<string> {
    const editableStates = [GramState.Publishing, GramState.Verified, GramState.Posted];
    const postIsEditable = !this.state || (this.state && !editableStates.includes(this.state));
    const needsTokenRefresh = !this.isInstagram && !this.isTiktok && isBlank(this.get('socialProfile')?.get('token'));
    const needsRefreshTokenRefresh = this.isPinterest && this.get('socialProfile')?.get('needsRefreshTokenRefresh');

    if (this.get('account')?.get('stopTheWorldOrPastDue')) {
      return 'stop_the_world';
    } else if (this.isTwitter && !isEmpty(this.platformError)) {
      return 'twitter_error';
    } else if (this.isPinterest && !isEmpty(this.platformError)) {
      return 'pinterest_error';
    } else if (this.isInstagram && !isEmpty(this.platformError)) {
      return 'instagram_error';
    } else if (this.isFacebook && !isEmpty(this.platformError)) {
      return 'facebook_error';
    } else if (this.isLinkedin && !isEmpty(this.platformError)) {
      return 'linkedin_error';
    } else if (this.isTiktok && !isEmpty(this.platformError)) {
      return 'tiktok_error';
    } else if (this.isThreads && !isEmpty(this.platformError)) {
      return 'threads_error';
    } else if (this.isSnapchat && !isEmpty(this.platformError)) {
      return 'snapchat_error';
    } else if (postIsEditable && (needsTokenRefresh || needsRefreshTokenRefresh)) {
      return 'no_token';
    }
    return null;
  }

  /**
   * Modal error text from the modal error code of the post
   */
  @computed('modalErrorCode', 'platformError')
  get modalErrorText(): string {
    if (this.modalErrorCode) {
      if (!isEmpty(this.platformError)) {
        const profileName = capitalize(this.modalErrorCode.split('_')[0]);
        return this.intl.t('calendar.modal.errors.message', { profileName });
      }
      return this.intl.t('calendar.modal.errors.' + this.modalErrorCode);
    }

    return '';
  }

  /**
   * High or low resolution URL depending on the display resolution
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
   */
  @computed('lowResOrProcessingUrl', 'highResOrProcessingUrl')
  get modalImageUrl(): string {
    if (window.devicePixelRatio >= 2) {
      return this.highResOrProcessingUrl;
    }
    return this.lowResOrProcessingUrl;
  }

  /**
   * `scheduledTime` as a moment object
   */
  @computed('scheduledTime')
  get moment(): Maybe<moment.Moment> {
    if (isNone(this.scheduledTime)) {
      return null;
    }
    return moment.unix(this.scheduledTime);
  }

  /**
   * Alert copy for a scheduled post in the past
   */
  @computed('intl.locale', 'isInstagram')
  get pastScheduleAlert(): string {
    if (this.isInstagram) {
      return this.intl.t('calendar.cannot_post_path_notification');
    } else if (this.isPinterest && this.isVideo) {
      return this.intl.t('calendar.cannot_post_path_approval');
    }
    return this.intl.t('calendar.cannot_post_path_immediate');
  }

  /**
   * Medium or small thumbnail for preview grid depending on device resolution
   */
  @computed('smallThumbnailOrProcessingUrl', 'medThumbnailOrProcessingUrl')
  get previewGridImageUrl(): string {
    if (window.devicePixelRatio >= 2) {
      return this.medThumbnailOrProcessingUrl;
    }
    return this.smallThumbnailOrProcessingUrl;
  }

  /**
   * Image preview URL
   */
  @computed('previewDataUrl', 'id', 'modalImageUrl', 'mediaItem.largeDisplayUrl')
  get previewUrl(): Maybe<string> {
    if (
      !isEmpty(this.previewDataUrl) &&
      (this.get('hasDirtyAttributes') || this.postMediaItems.any((pmi) => pmi.get('hasDirtyAttributes')))
    ) {
      return this.previewDataUrl;
    } else if (!isNone(this.id)) {
      return this.modalImageUrl;
    }
    return this.get('mediaItem')?.get('largeDisplayUrl');
  }

  /**
   * Processing image URL used for post images that processing
   */
  @computed('id', 'isRecent')
  get processingImageUrl(): string {
    if (this.isRecent || isNone(this.id)) {
      return 'https://image-cdn.later.com/static/img--imageprocessing.gif';
    }
    return '/api/v2/grams/' + this.id + '/processing_image';
  }

  /**
   * Whether the post has been scheduled and not posted
   */
  @computed('scheduledTime', 'posted')
  get scheduled(): boolean {
    return !isEmpty(this.scheduledTime) && !this.posted;
  }

  /**
   * Whether the post is scheduled in the future
   */
  @computed('scheduled', 'scheduledTime')
  get scheduledInFuture(): boolean {
    if (!this.scheduled) {
      return false;
    }
    if (!this.scheduledTime) {
      return false;
    }

    return this.scheduled && this.scheduledTime > timestamp();
  }

  /**
   * Whether to show the hashtag limit warning for a post
   */
  @computed('hashtagsLeft', 'exceededHashtagLimit', 'isInstagram')
  get showHashtagLimitWarning(): boolean {
    const showHashtagLimitWarningLimit = this.isInstagram ? 7 : 0;
    return this.hashtagsLeft < showHashtagLimitWarningLimit && !this.exceededHashtagLimit;
  }

  /**
   * Whether to show remaining characters left
   */
  @computed('charactersLeft', 'isFacebook')
  get showRemainingCharacterCount(): boolean {
    const showCountThreshold = this.isFacebook ? 1000 : 100;
    return this.charactersLeft < showCountThreshold;
  }

  /**
   * Default segment event properties for scheduled post.
   * Also includes performance tracking for post builder v2
   */
  @computed('socialProfile.nickname', 'scheduledTime', 'calendarDrop', 'gramType', 'id')
  get defaultScheduleEventArgs(): ScheduleEventArgs {
    performance.mark('EndPostV2');
    const postTime = this.performanceTracking.log('PostV2');
    const scheduledTime = this.get('scheduledTime');

    return {
      builder_version: 'v2',
      creation_time: postTime === null ? null : Math.round(postTime / 1000),
      time: scheduledTime ? moment.unix(scheduledTime).toISOString() : null,
      profile: this.get('socialProfile')?.get('nickname'),
      calendar: this.calendarDrop ? this.calendarDrop : false,
      type: this.isText ? this.textPostType : this.gramType,
      post_id: this.id,
      photo_text: this.imageText,
      photo_text_present: !areStringsEqual(this.imageText, 'null_value'),
      media_item_count: this.postMediaItems.length,
      created_from_draft: !!this.changedAttributes().isDraft,
      ...this.schedulePost.changeMediaEventArgs
    };
  }

  /**
   * Segment event properties for a scheduled Pinterest post
   */
  @computed(
    'defaultScheduleEventArgs.[]',
    'linkUrl',
    'imageUrl',
    'isTrimmedVideo',
    'isCroppedVideo',
    'firstPostMediaItem.thumbOffset'
  )
  get pinterestScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      ...{
        link: this.linkUrl,
        image_url: this.imageUrl,
        video_trim: !!this.isTrimmedVideo,
        video_crop: !!this.isCroppedVideo,
        click_tracking: this.clickTracking
      },
      ...(this.isVideo && { thumbnail_offset: this.get('firstPostMediaItem')?.get('thumbOffset') })
    };
  }

  /**
   * Segment event properties for a scheduled Twitter post
   */
  @computed('defaultScheduleEventArgs.[]', 'clickTracking', 'isCroppedVideo', 'isTrimmedVideo')
  get twitterScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      click_tracking: this.clickTracking,
      video_trim: !!this.isTrimmedVideo,
      video_crop: !!this.isCroppedVideo
    };
  }

  /**
   * Segment event properties for a scheduled Facebook post
   */
  @computed('defaultScheduleEventArgs.[]', 'firstPostMediaItem.thumbOffset', 'isCroppedVideo', 'isTrimmedVideo')
  get facebookScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      post_type: this.isReel ? PostType.Reel : PostType.FacebookPage,
      video_trim: !!this.isTrimmedVideo,
      video_crop: !!this.isCroppedVideo,
      ...(this.isVideo && { thumbnail_offset: this.get('firstPostMediaItem')?.get('thumbOffset') })
    };
  }

  /**
   * Segment event properties for a scheduled TikTok post
   */
  @computed(
    'defaultScheduleEventArgs.[]',
    'imageUrl',
    'autoPublish',
    'isTrimmedVideo',
    'isCroppedVideo',
    'captionHashtags',
    'firstPostMediaItem.thumbOffset',
    'mediaItem.videoUrl',
    'isFirstCommentActive'
  )
  get tiktokScheduleEventArgs(): ScheduleEventArgs {
    const profileType = this.get('socialProfile')?.get('isTiktokLoginKitAccount') ? 'personal' : 'business';

    return {
      ...this.defaultScheduleEventArgs,
      platform: 'web',
      url: this.get('mediaItem')?.get('videoUrl'),
      auto_publish: this.autoPublish,
      first_comment: this.isFirstCommentActive,
      video_trim: !!this.isTrimmedVideo,
      video_crop: !!this.isCroppedVideo,
      hashtags_count: this.captionHashtags,
      thumbnail_offset: this.get('firstPostMediaItem')?.get('thumbOffset'),
      tiktok_profile_type: profileType,
      allowed_comments: this.allowTiktokComment,
      allowed_duet: this.allowTiktokDuet,
      allowed_stitches: this.allowTiktokStitch
    };
  }

  /**
   * Segment event properties for a scheduled Instagram post
   */
  @computed(
    'defaultScheduleEventArgs.[]',
    'imageUrl',
    'autoPublish',
    'locationName',
    'taggedUsers',
    'firstPostMediaItem.thumbOffset',
    'isTrimmedVideo',
    'isCroppedVideo',
    'isReel',
    'shareToFeed'
  )
  get instagramScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      ...{
        url: this.imageUrl,
        auto_publish: this.autoPublish,
        tagged_location: this.locationName,
        tagged_people: this.taggedUsers,
        video_trim: !!this.isTrimmedVideo,
        video_crop: !!this.isCroppedVideo,
        first_comment: this.isFirstCommentActive,
        hashtags_count_captions: this.captionHashtags,
        hashtags_count_first_comment: this.firstCommentHashtags,
        total_hashtags_count: this.hashtagsCount,
        post_type: this.isReel ? PostType.Reel : PostType.InstagramFeed,
        reel_shared_to_feed: this.isInstagramReel && this.autoPublish ? this.shareToFeed : false,
        invited_collaborator: this.taggedCollaborators
      },
      ...(this.isVideo && { thumbnail_offset: this.get('firstPostMediaItem')?.get('thumbOffset') })
    };
  }

  /**
   * Segment event properties for a scheduled Linkedin post
   */
  @computed(
    'defaultScheduleEventArgs.[]',
    'imageUrl',
    'socialProfile.isLinkedinPersonalProfile',
    'firstPostMediaItem.thumbOffset',
    'isVideo',
    'isTrimmedVideo',
    'isCroppedVideo'
  )
  get linkedinScheduleEventArgs(): ScheduleEventArgs {
    const profileType = this.get('socialProfile')?.get('isLinkedinPersonalProfile') ? 'personal' : 'organization';

    return {
      ...this.defaultScheduleEventArgs,
      ...{
        url: this.imageUrl,
        auto_publish: true,
        linkedin_profile_type: profileType,
        video_trim: !!this.isTrimmedVideo,
        video_crop: !!this.isCroppedVideo
      },
      ...(this.isVideo && { thumbnail_offset: this.get('firstPostMediaItem')?.get('thumbOffset') })
    };
  }

  /**
   * Segment event properties for a scheduled Youtube post
   */
  @computed(
    'defaultScheduleEventArgs.[]',
    'madeForKids',
    'visibility',
    'category',
    'keywordTags.[]',
    'notifySubscribers'
  )
  get youtubeScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      made_for_kids: this.madeForKids ? 'true' : 'false',
      visibility: this.visibility,
      category_id: this.category,
      tags: this.keywordTags?.toString(),
      notify_subscribers: this.notifySubscribers ? 'true' : 'false'
    };
  }

  /**
   * Segment event properties for a scheduled Threads post
   */
  @computed('defaultScheduleEventArgs.[]', 'linkUrl', 'isCroppedVideo', 'isTrimmedVideo')
  get threadsScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      link: this.linkUrl,
      video_crop: !!this.isCroppedVideo,
      video_trim: !!this.isTrimmedVideo
    };
  }

  /**
   * Segment event properties for a scheduled Snapchat post
   */
  @computed('defaultScheduleEventArgs.[]', 'isSpotlight', 'saveToProfile')
  get snapchatScheduleEventArgs(): ScheduleEventArgs {
    return {
      ...this.defaultScheduleEventArgs,
      post_type: this.isSpotlight ? PostType.Spotlight : PostType.SnapchatStory,
      save_to_profile: this.saveToProfile
    };
  }

  /**
   * Post's associated scheduleEventArgs property
   */
  @computed('socialProfile.profileType', 'isLinkedin')
  get scheduleEventArgs(): ScheduleEventArgs {
    const profileType = this.get('socialProfile')?.get('profileType');
    switch (profileType) {
      case 'facebook':
        return this.facebookScheduleEventArgs;
      case 'instagram':
        return this.instagramScheduleEventArgs;
      case 'linkedin':
        return this.linkedinScheduleEventArgs;
      case 'pinterest':
        return this.pinterestScheduleEventArgs;
      case 'snapchat':
        return this.snapchatScheduleEventArgs;
      case 'threads':
        return this.threadsScheduleEventArgs;
      case 'tiktok':
        return this.tiktokScheduleEventArgs;
      case 'twitter':
        return this.twitterScheduleEventArgs;
      case 'youtube':
        return this.youtubeScheduleEventArgs;
      default:
        throw new Error(`Unable to get schedule event arguments from profile type, "${profileType}"`);
    }
  }

  /**
   * Small thumbnail URL or processing URL if none
   */
  @computed('smallThumbnailUrl', 'processingImageUrl')
  get smallThumbnailOrProcessingUrl(): string {
    if (!isNone(this.smallThumbnailUrl)) {
      return this.smallThumbnailUrl;
    }
    return this.processingImageUrl;
  }

  /**
   * Media medium thumbnail URL if available or the medium thumbnail URL
   */
  @computed('medThumbnailUrl', 'firstMediaItem.medThumbnailOrProcessingUrl', 'isInstagramReel', 'customCoverPMI')
  get medThumbnailOrMediaMedThumbnail(): string {
    if (this.isEmptyDraft) {
      return '';
    }

    if (this.isInstagramReel && this.customCoverPMI?.previewUrl) {
      return this.customCoverPMI.previewUrl ?? this.firstMediaItem?.get('medThumbnailOrProcessingUrl');
    }
    return this.medThumbnailUrl ?? this.firstMediaItem?.get('medThumbnailOrProcessingUrl');
  }

  /**
   * Media image Url or Media item medium thumbnail URL
   */
  @computed('isEmptyDraft', 'imageUrl', 'firstMediaItem.medResOrProcessingUrl', 'isInstagramReel', 'customCoverPMI')
  get imageUrlOrMedResOrProcessingUrl(): string {
    if (this.isEmptyDraft) {
      return '';
    }

    if (this.isInstagramReel && this.customCoverPMI?.previewUrl) {
      return this.customCoverPMI.previewUrl ?? this.firstMediaItem?.get('medResOrProcessingUrl');
    }
    return this.imageUrl ?? this.firstMediaItem?.get('medResOrProcessingUrl');
  }

  /**
   * Posted or scheduled time as a string in ISO8601 format with UTC offset
   * (e.g. "2021-05-22T08:00:00-07:00")
   */
  @computed('startTime', 'userConfig.currentTimeZone.identifier')
  get start(): Maybe<string> {
    const identifier = this.userConfig.currentTimeZone?.identifier;

    if (isNone(this.startTime) || !identifier || !this.startTime.tz(identifier)) {
      return null;
    }
    return this.startTime.tz(identifier).format();
  }

  /**
   * Posted or scheduled time as a moment object
   */
  @computed('scheduledTime', 'postedTime')
  get startTime(): Moment | null {
    if (!isNone(this.postedTime)) {
      return moment.unix(this.postedTime);
    } else if (isNone(this.scheduledTime)) {
      return null;
    }
    return moment.unix(this.scheduledTime);
  }

  /**
   * End time of a posted or scheduled story (+1 day)
   * as a moment object
   */
  @computed('startTime')
  get storyEndTime(): Moment | null {
    if (!this.startTime) {
      return null;
    }
    const storyStartTime = this.startTime.clone();
    const time = moment(storyStartTime).add(1, 'day');
    return time;
  }

  /**
   * Whether the text post is a text with link, link or text.
   * If at least one link is included with any additional text it is
   * considered a text with link post.
   */
  @computed('caption')
  get textPostType(): string {
    if (this.captionHasURL) {
      return this.caption.split(' ').length > 1 ? 'text with link' : 'link';
    }
    return 'text';
  }

  /**
   * A comma separated string of tagged users in the post
   */
  @computed('userTags.[]')
  get taggedUsers(): string {
    return this.userTags?.map((tag) => tag.username).join(',') ?? '';
  }

  /**
   * A comma separated string of collaborators in the post
   */
  get taggedCollaborators(): string {
    return this.collaborators.join(',');
  }

  /**
   * Thumbnail URL if avaiable or the processing URL
   */
  @computed('id', 'smallThumbnailUrl', 'mediaItem.thumbnail', 'processingImageUrl')
  get thumbnailOrProcessingUrl(): string {
    if (!isNone(this.smallThumbnailUrl)) {
      return this.smallThumbnailUrl;
    } else if (!isNone(this.id)) {
      return this.processingImageUrl;
    } else if (!isNone(this.mediaItem) && !isNone(this.mediaItem.get('smallThumbnailUrl'))) {
      return this.mediaItem.get('smallThumbnailUrl') || this.processingImageUrl;
    }
    return this.processingImageUrl;
  }

  /**
   * Whether the post has a verified time
   */
  @computed('verifiedTime')
  get verified(): boolean {
    return !isEmpty(this.verifiedTime);
  }

  /**
   * Whether the notification post is verified on Instagram/Tiktok
   */
  get unverified(): boolean {
    if (this.isNotificationPost) {
      return this.posted && !this.verified;
    }
    return false;
  }

  /**
   * Minute of week rounded to the closest half hour for bttp
   */
  @computed('scheduledTime')
  get roundedHalfHourMow(): Maybe<number> {
    if (isNone(this.scheduledTime)) {
      return null;
    }

    const t = moment(this.scheduledTime).utc();

    const wday = t.day();
    const hour = t.hour();
    const minute = t.minute();

    let newMinute = minute;

    if (minute < 15) {
      newMinute = 0;
    } else if (minute >= 15 && minute < 45) {
      newMinute = 30;
    } else if (minute >= 45) {
      newMinute = 60;
    }

    const diff = newMinute - minute;

    let m = wday * convert.day().toMinutes();
    m = m + hour * MINUTES_PER_HOUR;
    m = m + minute;

    m = m + diff;

    return m;
  }

  /**
   * Whether the post is a notification post
   */
  @computed('socialProfile.hasDevices', 'autoPublish')
  get isNotificationPost(): boolean {
    return (
      (this.isInstagram || this.isTiktok) &&
      !this.autoPublish &&
      (this.get('socialProfile')?.get('hasDevices') ?? false)
    );
  }

  /**
   * Whether a post is disabled due to being past the social profile's post limit
   */
  @computed('id', 'socialProfile.hasPostsLeft')
  get isPastPostLimitDisabled(): boolean {
    return !(this.get('socialProfile')?.get('hasPostsLeft') ?? false) && isNone(this.id);
  }

  get isAutoPublishOnlyProfile(): boolean {
    return (
      this.isFacebook ||
      this.isLinkedin ||
      this.isPinterest ||
      this.isTwitter ||
      this.isYoutube ||
      this.isThreads ||
      this.isSnapchat
    );
  }

  get isNonPublishedPost(): boolean {
    return !this.autoPublish && !this.isNotificationPost && !this.isAutoPublishOnlyProfile;
  }

  get isGifPost(): boolean {
    return this.postMediaItems.isAny('isGif', true);
  }

  get isCarouselPost(): boolean {
    if (this.isInstagramReel || this.isLinkedinVideo) {
      return false;
    }

    return Number(this.postMediaItems.length) > 1;
  }

  get canBoostPost(): boolean {
    return Boolean(this.state === GramState.Verified && this.mediaId && this.get('socialProfile').get('canBoostPost'));
  }
  /**
   * Post can be converted to a Draft post. In order for a post to be eligible for
   * being saved as a draft, it must:
   *  a. be a new post (determined by no `state` property existing yet)
   *  b. be an existing draft post (determined by `state` property reading "draft")
   *
   * @returns Post can be saved as draft
   */
  get canSaveAsDraft(): boolean {
    if (!this.state || this.isNotificationPost) {
      return true;
    }
    return [GramState.Draft, GramState.Scheduled].includes(this.state);
  }

  get customCoverPMI(): Maybe<PostMediaItemModel> {
    return this.isInstagramReel || this.isLinkedinVideo
      ? this.postMediaItems.filter((pmi) => pmi.ordering === 1).lastObject
      : undefined;
  }

  /**
   * Whether this post has a valid existing LinkinbioPost
   * Copied posts can have linkinbioPosts that are valid but don't have an id yet
   */
  get hasExistingLinkinbioPost(): boolean {
    return Boolean(this.linkinbioPost?.get('id') || this.linkinbioPost?.get('linkUrl'));
  }

  get isHighPerformingPost(): boolean {
    return this.isPosted && !isEmpty(this.metricsAchieved);
  }

  /**
   * Creates an instance of the IgPreviewPost class
   */
  igPreviewPost(): IgPreviewPost {
    const createParams: IgPreviewPostParams = {
      id: 'ig-gram-' + this.id,
      socialProfile: this.get('socialProfile'),
      gram: this,
      scheduledTime: this.scheduledTime ?? undefined
    };

    return IgPreviewPost.create(createParams);
  }

  /**
   * Creates an uncommitted postMediaItem record from a mediaItem object
   */
  createPMIFromMediaItem({
    mediaItem,
    originalPMI,
    ordering = 0
  }: {
    mediaItem: Maybe<MediaItemModel>;
    originalPMI?: PostMediaItemModel;
    ordering: number;
  }): PostMediaItemModel {
    return this.store.createRecord('post-media-item', {
      altText: mediaItem?.altText ?? '',
      height: mediaItem?.height,
      imageUrl: mediaItem?.highResUrl,
      largeThumbnailUrl: mediaItem?.largeThumbnailUrl,
      mediaItem,
      mediaType: mediaItem?.mediaType,
      medThumbnailUrl: mediaItem?.medThumbnailUrl,
      ordering,
      smallThumbnailUrl: mediaItem?.smallThumbnailUrl,
      userTags: originalPMI?.userTags ?? [],
      videoUrl: mediaItem?.videoUrl,
      width: mediaItem?.width
    });
  }

  /**
   * Creates an uncommited postMediaItem record from a postMediaItem object
   */
  createPMIFromPMI({
    postMediaItem,
    ordering = 0
  }: {
    postMediaItem: PostMediaItemModel;
    ordering: number;
  }): PostMediaItemModel {
    return this.store.createRecord('post-media-item', {
      altText: postMediaItem.get('altText') ?? '',
      cropArray: postMediaItem.get('cropArray'),
      endTime: postMediaItem.get('endTime'),
      mediaItem: postMediaItem.get('mediaItem'),
      mediaType: postMediaItem.get('mediaType'),
      ordering,
      previewDataUrl: postMediaItem.get('previewDataUrl'),
      startTime: postMediaItem.get('startTime'),
      thumbOffset: postMediaItem.get('thumbOffset'),
      transformation: postMediaItem.get('transformation')
    });
  }

  affixLinkinbioPost(linkinbioPost: LinkinbioPostModel): void {
    const targetProfile = this.get('socialProfile');
    const copiedLinkinbioPost = this.store.createRecord('linkinbio-post', {
      gram: this,
      highResUrl: this.postMediaItems.firstObject?.imageUrl,
      linkUrl: linkinbioPost.get('linkUrl'),
      linkinbioPostLinks: linkinbioPost.get('linkinbioPostLinks'),
      networkType: targetProfile?.get('profileType'),
      postType: this.get('postType') || INSTAGRAM_POST_TYPE.FEED,
      socialProfile: targetProfile
    });
    this.set('linkinbioPost', copiedLinkinbioPost);
  }

  copy(): GramModel {
    return this.store.createRecord('gram', {
      caption: this.caption,
      createdTime: timestamp(),
      cropArray: this.cropArray,
      originalCaption: this.originalCaption,
      sourceUsername: this.sourceUsername,
      title: this.title,
      gramType: this.gramType,
      mediaItem: this.mediaItem,
      postMediaItems: this.postMediaItems.map((pmi) => {
        const newPMI = this.createPMIFromPMI({ postMediaItem: pmi, ordering: pmi.ordering });
        newPMI.set('userTags', pmi.userTags);
        newPMI.set('productTags', pmi.productTags);
        return newPMI;
      }),
      user: this.user
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    gram: GramModel;
  }
}
