import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import moment from 'moment';

import { ErrorSeverity } from 'later/services/errors';
import { arrayDiff } from 'later/utils/array-methods';
import { OauthSocialProfileType } from 'later/utils/constants';
import { timestamp } from 'later/utils/time-format';
import {
  InsufficientPermissionsError,
  UnknownLoginError,
  FetchPageError,
  ExtensionBlockedError
} from 'shared/errors/instagram-auth';
import redirect from 'shared/utils/redirect';

import type ConnectProfilesService from './connect-profiles';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type OnboardingService from 'later/services/onboarding';
import type SegmentService from 'later/services/segment';

enum FacebookResponseStatus {
  Connected = 'connected',
  NotAuthorized = 'not_authorized',
  Unknown = 'unknown'
}

interface FacebookLoginParams {
  return_scopes: boolean;
  scope: string;
}

interface FacebookPagePicture {
  data: {
    height: number;
    is_silhouette: boolean;
    url: string;
    width: number;
  };
}

interface InstagramProfile {
  id: string;
  username: string;
  profile_picture_url: string;
  name: string;
}

interface FacebookPage {
  picture: FacebookPagePicture;
  name: string;
  name_with_location_descriptor: string;
  access_token: string;
  id: string;
  instagram_business_account?: InstagramProfile;
  tasks: string[];
}

interface AccountsResponse {
  data: FacebookPage[];
  error?: {
    message: string;
    type: string;
    code: number;
    error_subcode: number;
    error_user_title: string;
    error_user_msg: string;
    fbtrace_id: string;
  };
  paging?: {
    cursor: {
      after: string;
      before: string;
    };
  };
}

const linkinbioAdditionalScopes = ['instagram_basic', 'pages_show_list', 'public_profile', 'pages_read_engagement'];

const instagramAdditionalScopes = [
  'business_management',
  'catalog_management',
  'instagram_basic',
  'instagram_content_publish',
  'instagram_manage_comments',
  'instagram_manage_insights',
  'instagram_shopping_tag_products',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_show_list',
  'read_insights'
];

const instagramLoginAdditionalScopes = [
  'instagram_business_basic',
  'instagram_business_content_publish',
  'instagram_business_manage_insights',
  'instagram_business_manage_comments',
  'instagram_business_manage_messages'
];

const socialInboxAdditionalScopes = ['pages_messaging', 'instagram_manage_messages'];

const fetchAccountsParams = {
  fields:
    'picture,username,name,name_with_location_descriptor,access_token,tasks,instagram_business_account.fields(id,username,profile_picture_url,name)',
  limit: 1000
} as const;

export default class InstagramAuthService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare onboarding: OnboardingService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare store: StoreService;

  @tracked authResponse?: fb.AuthResponse;
  @tracked canShowInstagramAuthStatus = false;
  @tracked facebookPages?: FacebookPage[];
  @tracked showInstagramSelector = false;
  @tracked showInstagramUpgradeModal = false;
  @tracked socialIdentityId?: string;

  get currentUserModel(): UserModel {
    return this.auth.currentUserModel;
  }

  get facebookLoginParams(): FacebookLoginParams {
    return {
      return_scopes: true,
      scope: this.instagramAdditionalScopes.join(',')
    };
  }

  get instagramAdditionalScopes(): string[] {
    const scopes = this.auth.currentAccount.rolloutLinkinbioLimitedInstagramPermissions
      ? linkinbioAdditionalScopes
      : instagramAdditionalScopes;
    if (this.auth.currentAccount.rolloutSocialInboxMeta) {
      return scopes.concat(socialInboxAdditionalScopes);
    }
    return scopes;
  }

  get instagramLoginAdditionalScopes(): string[] {
    return instagramLoginAdditionalScopes;
  }

  get prevKey(): string {
    return `prevInstagramAuthStatusDate_${this.currentUserModel?.id}`;
  }

  get nextKey(): string {
    return `nextInstagramAuthStatusDate_${this.currentUserModel?.id}`;
  }

  get pageName(): string {
    if (this.router.currentRouteName.includes('calendar')) {
      return 'calendar';
    }

    if (this.router.currentRouteName.includes('groups')) {
      return 'settings';
    }

    return this.router.currentRouteName;
  }

  @action
  closeInstagramModal(): void {
    this.showInstagramSelector = false;
    this.facebookPages = [];
    this.authResponse = undefined;
  }

  // Note: Passing refreshSocialProfiles = [SocialProfileModel] to this function means it's a refresh flow
  // Note: Passing refreshSocialProfiles = [] means it's only a user model facebookToken refresh, no profiles will be refreshed
  authenticateWithFacebookGraphApi = dropTask(
    async ({
      socialIdentityId,
      refreshSocialProfiles
    }: {
      socialIdentityId?: string;
      refreshSocialProfiles?: SocialProfileModel[];
    }) => {
      if (this.#facebookSdkDidNotLoad()) {
        return this.#showFacebookSdkError();
      }

      this.socialIdentityId = socialIdentityId || undefined;
      this.authResponse = undefined;

      await this.#loginWithFacebook()
        .then(async (response) => await this._handleFacebookResponse.perform(response, refreshSocialProfiles))
        .catch((error) => this.#handleError(error));
    }
  );

  refreshInstagramLogin = dropTask(
    async ({ socialProfile, redirectPath }: { socialProfile: SocialProfileModel; redirectPath?: string }) => {
      const oAuthPath = this.connectProfiles.oAuthPath({
        socialProfileType: OauthSocialProfileType.Instagram,
        redirectPath: redirectPath ?? this.router.currentURL
      });

      const params = `group_id=${socialProfile.get('group').get('id')}&refresh_social_profile_id=${socialProfile.id}`;
      redirect(`${oAuthPath}&${params}`);
    }
  );

  #facebookSdkDidNotLoad(): boolean {
    return typeof FB === 'undefined';
  }

  #showFacebookSdkError(): void {
    this.errors.log('@service:instagram-auth | Facebook Javascript SDK is unavailable.');
    this.alerts.warning(
      this.intl.t('alerts.account.controllers.groups.facebook.cant_load_sdk', {
        title: this.intl.t('shared_phrases.something_went_wrong')
      })
    );
  }

  #handleError(error: InsufficientPermissionsError | UnknownLoginError | string): void {
    if (error instanceof InsufficientPermissionsError) {
      this.alerts.warning(
        this.intl.t('errors.instagram_auth.insufficient_permissions.message', { permissions: error.message }),
        {
          title: this.intl.t('errors.instagram_auth.insufficient_permissions.title')
        }
      );
    } else if (error instanceof UnknownLoginError) {
      this.authResponse = undefined;
    } else {
      this.alerts.warning(error, { title: this.intl.t('shared_phrases.something_went_wrong') });
      this.authResponse = undefined;
    }
  }

  #loginWithFacebook(): Promise<fb.StatusResponse> {
    return new Promise((resolve, reject) => {
      FB.login((response: fb.StatusResponse) => {
        if (response.status === FacebookResponseStatus.Connected) {
          resolve(response);
        } else if (response.status === FacebookResponseStatus.Unknown) {
          reject(new UnknownLoginError(''));
        } else {
          reject(new Error(`Login error: ${response.status}`));
        }
      }, this.facebookLoginParams);
    });
  }

  _handleFacebookResponse = task(async (response: fb.StatusResponse, refreshSocialProfiles?: SocialProfileModel[]) => {
    this.#logResponse(response);
    const declinedScopes = this.#getDeclinedScopes(response.authResponse.grantedScopes);
    if (declinedScopes.length) {
      throw new InsufficientPermissionsError(
        declinedScopes.map((declinedScope) => declinedScope.replace(/_/g, ' ')).join(', ')
      );
    } else {
      this.authResponse = response.authResponse;

      await this._saveUserModelToken.perform(response);

      if (refreshSocialProfiles && !refreshSocialProfiles.length) {
        // Note: only user model facebookToken refresh
        return;
      }

      if (!refreshSocialProfiles) {
        this.showInstagramSelector = true;
      }

      await this._fetchFacebookPages.perform();

      if (refreshSocialProfiles && refreshSocialProfiles.length) {
        await this._refreshSocialProfiles.perform(refreshSocialProfiles);
      }
    }
  });

  #logResponse(response: fb.StatusResponse): void {
    this.errors.log(
      '@service:instagram-auth | authenticateWithFacebookGraphApi response',
      { data: JSON.stringify(response) },
      ErrorSeverity.Debug
    );
  }

  #getDeclinedScopes(grantedScopes: string | undefined): string[] {
    return grantedScopes ? arrayDiff(this.instagramAdditionalScopes, grantedScopes.split(',')) : [];
  }

  _saveUserModelToken = task(async (response: fb.StatusResponse) => {
    try {
      const { userID, accessToken } = response.authResponse;
      this.currentUserModel.setProperties({
        facebookUid: userID,
        facebookToken: accessToken
      });
      this.currentUserModel.save();
    } catch (error) {
      this.errors.log(
        `@service:instagram-auth | Unable to #saveUserModelToken for user.id: ${this.auth.currentUserModel?.id}`,
        error
      );
      throw new Error(this.intl.t('alerts.generic_error_message'));
    }
  });

  _fetchFacebookPages = task(async () => {
    this.facebookPages = [];

    await this._fetchPages
      .perform()
      .then((facebookPages: FacebookPage[]) => (this.facebookPages = facebookPages))
      .catch((error) => this.#handleFetchPagesError(error));
  });

  _fetchPages = task(async () => {
    return new Promise((resolve, reject) => {
      FB.api('/me/accounts', fetchAccountsParams, async (accountsResponse: AccountsResponse) => {
        if (!accountsResponse) {
          reject(new FetchPageError());
        }
        if (accountsResponse.error) {
          if (accountsResponse.error.error_subcode == 1357045) {
            reject(new ExtensionBlockedError());
          }
          reject(new Error(JSON.stringify(accountsResponse.error)));
        }
        resolve(accountsResponse.data);
      });
    });
  });

  #handleFetchPagesError(error: FetchPageError | ExtensionBlockedError | Error): void {
    if (error instanceof ExtensionBlockedError) {
      this.alerts.warning(this.intl.t('errors.instagram_auth.request_blocked.message'));
    } else if (error instanceof FetchPageError) {
      this.alerts.warning(this.intl.t('errors.instagram_auth.fetch_page.message'));
    } else {
      this.alerts.warning(
        this.intl.t('errors.instagram_auth.fetch_page_subcode.message', {
          subcode: error.message
        })
      );
    }
  }

  createInstagramProfile = task(async (selectedPage: FacebookPage) => {
    if (
      !this.authResponse ||
      !this.authResponse.grantedScopes ||
      !selectedPage.id ||
      !selectedPage.instagram_business_account
    ) {
      return;
    }

    const socialProfile = this.store.createRecord('social-profile');
    const socialProfileAttributes = {
      account: this.auth.currentAccount,
      additionalPermissionScope: this.authResponse.grantedScopes.split(','),
      authorizingFacebookUid: this.authResponse.userID,
      autoPublishFollowUp: true,
      avatarUrl: selectedPage.instagram_business_account.profile_picture_url,
      businessAccountId: selectedPage.instagram_business_account.id,
      businessAccountToken: selectedPage.access_token,
      facebookPageId: selectedPage.id,
      group: this.auth.currentGroup,
      nickname: selectedPage.instagram_business_account.username,
      profileColorClass: 'igpink',
      profileType: 'instagram',
      shorttermUserToken: this.authResponse.accessToken,
      uid: selectedPage.instagram_business_account.id
    };

    socialProfile.setProperties(socialProfileAttributes);

    if (this.socialIdentityId) {
      const socialIdentity = this.store.peekRecord('social-identity', this.socialIdentityId);
      socialProfile.set('socialIdentity', socialIdentity);
    }

    try {
      const savedProfile = await socialProfile.save();
      this.alerts.success(
        this.intl.t('alerts.account.controllers.groups.facebook.added', { name: savedProfile.nickname })
      );
      this.#sanitizeSocialSets(savedProfile);
    } catch (error) {
      this.errors.log(
        `@service:instagram-auth | Unable to createInstagramProfile for user.id: ${this.auth.currentUserModel?.id}, socialProfile.nickname: ${socialProfile.nickname}, uid: ${socialProfile.uid}`,
        error?.errors?.[0]?.detail || error
      );
      if (error?.errors?.[0]?.detail) {
        this.alerts.alert(error.errors[0].detail, {
          title: this.intl.t('shared_phrases.something_went_wrong')
        });
      } else {
        this.alerts.alert(error, {
          title: this.intl.t('shared_phrases.something_went_wrong')
        });
      }
      socialProfile.rollbackAttributes();
    }
  });

  _refreshSocialProfiles = task(async (refreshSocialProfiles: SocialProfileModel[]) => {
    if (!this.facebookPages || !this.authResponse || !this.authResponse.grantedScopes) {
      return;
    }

    const promises: Promise<void>[] = [];

    refreshSocialProfiles.forEach((refreshSocialProfile) => {
      const facebookPage = this.facebookPages?.find(
        (facebookPage) => facebookPage.instagram_business_account?.username === refreshSocialProfile.nickname
      );
      if (facebookPage && facebookPage.instagram_business_account && this.authResponse) {
        const socialProfileAttributes = {
          additionalPermissionScope: this.authResponse.grantedScopes?.split(','),
          authorizingFacebookUid: this.authResponse.userID,
          autoPublishFollowUp: true,
          avatarUrl: facebookPage.instagram_business_account.profile_picture_url,
          businessAccountId: facebookPage.instagram_business_account.id,
          businessAccountToken: facebookPage.access_token,
          facebookPageId: facebookPage.id,
          shorttermUserToken: this.authResponse.accessToken,
          uid: facebookPage.instagram_business_account.id
        };
        try {
          refreshSocialProfile.setProperties(socialProfileAttributes);
          promises.push(this._updateSocialProfile.perform(refreshSocialProfile));
        } catch (error) {
          this.errors.log(
            `@service:instagram-auth | Unable to refreshSocialProfile for user.id: ${this.auth.currentUserModel?.id}, socialProfile.nickname: ${refreshSocialProfile.nickname}, uid: ${refreshSocialProfile.uid}`,
            error?.errors?.[0]?.detail || error
          );
          if (error?.errors?.[0]?.detail) {
            this.alerts.alert(error.errors[0].detail, {
              title: this.intl.t('shared_phrases.something_went_wrong')
            });
          } else {
            this.alerts.alert(error, {
              title: this.intl.t('shared_phrases.something_went_wrong')
            });
          }
          refreshSocialProfile.rollbackAttributes();
        }
      } else {
        this.segment.track('refresh_facebook_token_failure', { social_profile: refreshSocialProfile.id });
        this.errors.log(
          `@service:instagram-auth | refreshSocialProfile: Missing Facebook Page or AuthResponse for user.id: ${this.auth.currentUserModel?.id}, socialProfile.nickname: ${refreshSocialProfile.nickname}, uid: ${refreshSocialProfile.uid}`,
          {
            debug: this.authResponse?.accessToken || ''
          },
          ErrorSeverity.Debug
        );
      }
    });

    await Promise.allSettled(promises);
  });

  #sanitizeSocialSets(savedProfile: SocialProfileModel): void {
    // Note: This function avoids the saved social profile from being present in more than one social set
    // Note: This can happen when stealing profiles from different access groups
    const updatedSocialIdentity = savedProfile.get('socialIdentity');
    this.store.peekAll('social-identity').forEach((socialIdentity) => {
      const duplicatedSocialProfile = socialIdentity
        .get('socialProfiles')
        .find((socialProfile) => socialProfile.id === savedProfile.id);
      if (duplicatedSocialProfile && socialIdentity.id !== updatedSocialIdentity.get('id')) {
        socialIdentity.get('socialProfiles').removeObject(duplicatedSocialProfile);
      }
    });
  }

  _updateSocialProfile = task(async (refreshSocialProfile: SocialProfileModel) => {
    try {
      await refreshSocialProfile.save();
    } catch (error) {
      this.errors.log('@service:instagram-auth | Unable to _updateSocialProfile', error);
      this.alerts.alert(this.intl.t('shared_phrases.something_went_wrong'), {
        preventDuplicates: true
      });
      refreshSocialProfile.rollbackAttributes();
    }
  });

  hasAllPermissions(socialProfile: SocialProfileModel): boolean {
    if (this.auth.currentAccount.rolloutInstagramLogin && socialProfile.isInstagramLogin) {
      return this.instagramLoginAdditionalScopes.every((scope) => socialProfile.permissionScope?.includes(scope));
    }
    return this.instagramAdditionalScopes.every((scope) => socialProfile.additionalPermissionScope?.includes(scope));
  }

  resetInstagramAuthStatusDates(): void {
    this.cache.remove(this.nextKey);
    this.cache.remove(this.prevKey);
    this.updateCache();
  }

  updateCache(): void {
    const today = timestamp();
    const curTimestamp = moment.unix(today);
    let daysSinceLastClose;

    const prevDisplayDate = this.cache.retrieve(this.prevKey);
    if (typeof prevDisplayDate === 'number') {
      const lastCloseTime = moment.unix(prevDisplayDate);
      daysSinceLastClose = Math.floor(curTimestamp.diff(lastCloseTime, 'days'));
    }

    const prevCredentialDisplayDate = this.cache.retrieve(this.nextKey);

    if (this.onboarding.hasNotCompletedOnboarding || this.auth.currentFirstDay) {
      this.canShowInstagramAuthStatus = false;
      return;
    } else if (
      typeof daysSinceLastClose === 'number' &&
      typeof prevCredentialDisplayDate === 'number' &&
      (daysSinceLastClose > 30 || daysSinceLastClose < prevCredentialDisplayDate)
    ) {
      this.canShowInstagramAuthStatus = false;
      return;
    }
    this.canShowInstagramAuthStatus = true;
    return;
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/instagram-auth': InstagramAuthService;
  }
}
